import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() => import("./dashboard/DashboardPage"));
const GroupPage = lazy(() => import("./group/GroupPage"));
const AdminPage = lazy(() => import("./admin/AdminPage"));
const RelatorioPage = lazy(() => import("./relatorio/RelatorioPage"));
const ClientePage = lazy(() => import("./cliente/ClientePage"));
const PedidoPage = lazy(() => import("./pedido/PedidoPage"));
const PedidoAnalisePage = lazy(() => import("./pedido-analise/PedidoAnalisePage"));
const PedidoRevisaoPage = lazy(() => import("./pedido-revisao/PedidoRevisaoPage"));
const PedidoSeparacaoPage = lazy(() => import("./pedido-separacao/PedidoSeparacaoPage"));
const RomaneioPage = lazy(() => import("./romaneio/RomaneioPage"));
const PedidoFiscalPage = lazy(() => import("./pedido-fiscal/PedidoFiscalPage"));
const PedidoFabricacaoPage = lazy(() => import("./pedido-fabricacao/PedidoFabricacaoPage"));
const PedidoExpedicaoPage = lazy(() => import("./pedido-expedicao/PedidoExpedicaoPage"));
const PedidoPosVendaPage = lazy(() => import("./pedido-pos-venda/PedidoPosVendaPage"));
const PedidoAssistenciaPage = lazy(() => import("./pedido-assistencia/PedidoAssistenciaPage"));
const PedidoConcluidoPage = lazy(() => import("./pedido-concluido/PedidoConcluidoPage"));
const OurivesPage = lazy(() => import("./ourives/OurivesPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {<Redirect exact from="/" to="/dashboard" />}

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/group" component={GroupPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/relatorio" component={RelatorioPage} />
                <Route path="/cliente" component={ClientePage} />
                <Route path="/pedido" component={PedidoPage} />
                <Route path="/pedido-analise" component={PedidoAnalisePage} />
                <Route path="/pedido-revisao" component={PedidoRevisaoPage} />
                <Route path="/pedido-separacao" component={PedidoSeparacaoPage} />
                <Route path="/romaneio" component={RomaneioPage} />
                <Route path="/pedido-fiscal" component={PedidoFiscalPage} />
                <Route path="/pedido-fabricacao" component={PedidoFabricacaoPage} />
                <Route path="/pedido-expedicao" component={PedidoExpedicaoPage} />
                <Route path="/pedido-pos-venda" component={PedidoPosVendaPage} />
                <Route path="/pedido-assistencia" component={PedidoAssistenciaPage} />
                <Route path="/pedido-concluido" component={PedidoConcluidoPage} />
                <Route path="/ourives" component={OurivesPage} />

                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
