export default {
    header: {
        self: {},
        items: [{}],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot",
            },
            {
                title: "Todos os pedidos",
                root: true,
                icon: "fas fa-copy",
                page: "pedido",
                bullet: "dot",
            },
            {
                title: "Clientes",
                root: true,
                icon: "fas fa-users",
                page: "cliente",
                bullet: "dot",
            },
            {
                title: "Ourives",
                root: true,
                icon: "fas fa-gem",
                page: "ourives",
                bullet: "dot",
            },
            {
                title: "Fases do pedido",
                root: true,
                bullet: "dot",
                icon: "fas fa-folder-open",
                submenu: [
                    {
                        title: "Análise",
                        bullet: "dot",
                        page: "pedido-analise",
                    },
                    {
                        title: "Revisão",
                        bullet: "dot",
                        page: "pedido-revisao",
                    },
                    {
                        title: "Separação",
                        bullet: "dot",
                        page: "pedido-separacao",
                    },
                    {
                        title: "Fiscal",
                        bullet: "dot",
                        page: "pedido-fiscal",
                    },
                    {
                        title: "Fabricação",
                        bullet: "dot",
                        page: "pedido-fabricacao",
                    },
                    {
                        title: "Expedição",
                        bullet: "dot",
                        page: "pedido-expedicao",
                    },
                    {
                        title: "Pós-venda",
                        bullet: "dot",
                        page: "pedido-pos-venda",
                    },
                    {
                        title: "Concluído",
                        bullet: "dot",
                        page: "pedido-concluido",
                    },
                    {
                        title: "Assistência",
                        bullet: "dot",
                        page: "pedido-assistencia",
                    },
                ],
            },
            {
                title: "Relatórios",
                root: true,
                bullet: "dot",
                icon: "fas fa-chart-pie",
                submenu: [
                    {
                        title: "Processos",
                        bullet: "dot",
                        page: "relatorio/relatorio01",
                    },
                    {
                        title: "Financeiro",
                        bullet: "dot",
                        page: "relatorio/relatorio02",
                    },
                    {
                        title: "Tempo por fase",
                        bullet: "dot",
                        page: "relatorio/relatorio03",
                    },
                ],
            },
            {
                title: "Acessos",
                root: true,
                icon: "fas fa-user-shield",
                bullet: "dot",
                submenu: [
                    {
                        title: "Administradores",
                        page: "admin",
                        bullet: "dot",
                    },
                    {
                        title: "Grupos de Acesso",
                        page: "group",
                        bullet: "dot",
                    },
                ],
            },
        ],
    },
};
